import { extendObservable } from 'mobx';

/**
 * UserStore
 */

 class UserStore {
     constructor() {
         extendObservable(this, {
             loading: true,
             isLoggedIn: false,
             username: '',
             firstName: '',
             lastName: ''
         })
     }
 }

 export default new UserStore();