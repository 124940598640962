import React from 'react';
import { observer } from 'mobx-react';
import UserStore from './stores/UserStore';
import LoginForm from './LoginForm';
import SubmitButton from './SubmitButton';
import Dashboard from './Dashboard';
import cegLogo from './ceg-logo.png';
import cegMainLogo from './ceg-digital-logo.png';
import falmouthLogo from './falmouth-logo.png';
import qmulLogo from './qmul-logo.png';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        partner: 'falmouth',
    }
  }

  async componentDidMount() {

    try {

      let res = await fetch('/isLoggedIn', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }

      });

      let result = await res.json();

      if (result && result.success) {
        UserStore.loading = false;
        UserStore.isLoggedIn = true;
        UserStore.username = result.username;
        UserStore.firstName = result.firstName;
        UserStore.lastName = result.lastName;
      }
      else {
        UserStore.loading = false;
        UserStore.isLoggedIn = false;
      }

    }
    catch(e) {
      UserStore.loading = false;
      UserStore.isLoggedIn = false;
    }

  }

  async doLogout() {

    try {

      let res = await fetch('/logout', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }

      });

      let result = await res.json();

      if (result && result.success) {
        UserStore.isLoggedIn = false;
        UserStore.username = '';
        UserStore.firstName = '';
        UserStore.lastName = '';
      }

    }
    catch(e) {
      console.log(e);
    }

  }

  render() {
    if (UserStore.loading) {
      return (
        <div className="app">
          <div className='container'>
            Loading, please wait...
          </div>
        </div>
      );
    }
    else {

      if (UserStore.isLoggedIn) {
        return (
          <div className="app">
            <div className='container'>
              <div className = "row" style={{marginBottom: "15px"}}>
                  <div className = "col-md-4 middleLogo">
                    <img src={cegMainLogo} alt="CEG Logo" width={200} />
                  </div>
                  <div className = "col-md-4 middleLogo">
                    <p><strong>Selected Partner:</strong></p>
                    { this.state.partner === 'falmouth' && <img src={falmouthLogo} alt="Falmouth Logo" width={150} />}
                    { this.state.partner === 'qmul' && <img src={qmulLogo} alt="QMUL Logo" width={175} />}
                  </div>
                <div className = "col-md-4">
                  <div class = "topRightNav">
                    <p style={{marginTop: "50px"}}>Welcome {UserStore.firstName}</p>
                    <label for="partner">Partner:</label>
                    <select name="partnerSelect" id="partnerSelect" class="partnerSel" value={this.state.partner} onChange={(e) => this.setState({ partner: e.target.value})} disabled>
                      <option value="" selected disabled hidden>Please select</option>
                      <option value="falmouth">Falmouth</option>
                    </select>
                    <SubmitButton
                        text={'Logout'}
                        disabled={false}
                        onClick={ () => this.doLogout() }
                    />
                  </div>
                </div>
              </div>
              <Dashboard partnerSelection = {this.state.partner} />
            </div>
          </div>
        );
      }

      return (
        <div className="app">
          <div className='container'>
            <img src={cegLogo} alt="CEG LOGO" height={250} width={250} />
            <LoginForm />
          </div>
        </div>
      );

    }

  }

}

export default observer(App);
