// We can use spreadsheet later on or have a webpage

export const Jan2021 = {

    CEM710: {courseId: "702"},
    CEM730: {courseId: "689"},
    GDE710: {courseId: "686"},
    GDE720: {courseId: "691"},
    ILL710: {courseId: "704"},
    ILL720: {courseId: "711"},
    WSS710: {courseId: "707"},
    WSS720: {courseId: "693"}

}


export const falmouthStudyBlockObject = {

    Jan2021: {

        AM750S2S3: {courseId: "888"},
        AM750S1S2: {courseId: "697"},
        GAM750S2S3: {courseId: "613"},
        GAM750S1S2: {courseId: "612"},
        CEM710: {courseId: "702"},
        CEM730: {courseId: "689"},
        CEM750S2S3: {courseId: "891"},
        CEM750S1S2: {courseId: "698"},
        AGF: {courseId: "1046"},
        GDE710: {courseId: "686"},
        GDE720: {courseId: "691"},
        GDE750S2S3: {courseId: "898"},
        GDE750S1S2: {courseId: "397"},
        ILL710: {courseId: "704"},
        ILL720: {courseId: "711"},
        ILL750S2S3: {courseId: "844"},
        ILL750S1S2: {courseId: "356"},
        EDU710: {courseId: "710"},
        EDU720: {courseId: "688"},
        PHO610: {courseId: "684"},
        PHO620: {courseId: "705"},
        PHO630: {courseId: "713"},
        PHO710: {courseId: "942"},
        PHO702: {courseId: "694"},
        PHO705S2S3: {courseId: "901"},
        PHO705S1S2: {courseId: "690"},
        WSS710: {courseId: "707"},
        WSS720: {courseId: "693"},
        WSS750S2S3: {courseId: "723"},
        WSS750S1S2: {courseId: "696"},
        COM710: {courseId: "654"},
        COM720: {courseId: "656"},
        JSM710: {courseId: "644"},
        JSM720: {courseId: "641"},
        MDC710: {courseId: "877"},
        MDC720: {courseId: "879"},
        GDD710: {courseId: "911"},
        IGD720: {courseId: "921"},
        UXD720: {courseId: "907"},

    },

    May2021: {

        AM750S2S3: {courseId: "888"}, // DONE
        GAM750S2S3: {courseId: "613"}, // DONE
        CEM710: {courseId: "890"}, // DONE
        CEM720: {courseId: "870"}, // DONE
        CEM750S3S1: {courseId: "892"}, // DONE
        CEM750S2S3: {courseId: "891"}, // DONE
        GDE710: {courseId: "864"}, // DONE
        GDE730: {courseId: "897"}, // DONE
        GDE750S3S1: {courseId: "899"}, // DONE
        GDE750S2S3: {courseId: "898"}, // DONE
        ILL710: {courseId: "900"}, // DONE
        ILL730: {courseId: "860"}, // DONE
        ILL750S3S1: {courseId: "845"}, // DONE
        ILL750S2S3: {courseId: "844"}, // DONE
        EDU720: {courseId: "846"}, // DONE
        PHO610: {courseId: "848"}, // DONE
        PHO620: {courseId: "855"}, // DONE
        PHO630: {courseId: "743"}, // DONE
        PHO710: {courseId: "943"}, // DONE
        PHO730: {courseId: "945"}, // DONE
        PHO705S3S1: {courseId: "902"}, // DONE
        PHO705S2S3: {courseId: "901"}, // DONE
        WSS710: {courseId: "903"}, // DONE
        WSS730: {courseId: "726"}, // DONE
        WSS750S3S1: {courseId: "904"}, // DONE
        WSS750S2S3: {courseId: "723"}, // DONE
        COM710: {courseId: "655"}, //DONE
        COM730: {courseId: "651"}, //DONE
        JSM710: {courseId: "645"}, // DONE
        JSM730: {courseId: "649"}, // DONE
        MDC710: {courseId: "878"}, // DONE
        MDC730: {courseId: "880"}, // DONE
        GDD710: {courseId: "912"}, // DONE
        GDD730: {courseId: "913"}, // DONE
    
    }

}

export const qmulStudyBlockObject = {

    Jan2021: {

        POLM077A201: {courseId: "323"}, // DONE
        POLM077B201: {courseId: "345"}, // DONE
        POLM091B201: {courseId: "346"}, // DONE
        POLM091B202: {courseId: "347"}, // DONE
        POLM091B203: {courseId: "348"}, // DONE
        POLM087A201: {courseId: "338"}, // DONE
        POLM087B201: {courseId: "349"}, // DONE
        POLM085B201: {courseId: "350"}, // DONE
        POLM085B202: {courseId: "351"}, // DONE
        CCDD200A20: {courseId: "332"}, // DONE
        CCDD200B20: {courseId: "352"}, // DONE
        CCDD211B20: {courseId: "353"}, // DONE
        IPH7100A201: {courseId: "326"}, // DONE
        IPH7100B201: {courseId: "354"}, // DONE
        IPH7103B201: {courseId: "355"}, // DONE
        IPH7103B202: {courseId: "356"}, // DONE
        IPH7105B201: {courseId: "360"}, // DONE
        IPH7104B201: {courseId: "357"}, // DONE
        IPH7104B202: {courseId: "358"}, // DONE
        IPH7106B201: {courseId: "359"}, // DONE

    },

    May2021: {
        
        POLM077B20: {courseId: "345"}, // DONE
        POLM090C201: {courseId: "363"}, // DONE
        POLM090C202: {courseId: "364"}, // DONE
        POLM090C203: {courseId: "365"}, // DONE
        POLM090C204: {courseId: "366"}, // DONE
        POLM075C201: {courseId: "367"}, // DONE
        POLM075C202: {courseId: "368"}, // DONE
        POLM075C203: {courseId: "369"}, // DONE
        POLM087B201: {courseId: "349"}, // DONE
        CCDD200B20: {courseId: "352"}, // DONE
        CCDD212C20: {courseId: "371"}, // DONE
        CCDD201C20: {courseId: "372"}, // DONE
        IPH7100B201: {courseId: "354"}, // DONE
        IPH7101C201: {courseId: "373"}, // DONE
        IPH7101C202: {courseId: "379"}, // DONE
        IPH7101C203: {courseId: "380"}, // DONE
        IPH7105C201: {courseId: "374"}, // DONE
        IPH7105C202: {courseId: "375"}, // DONE
        IPH7107C201: {courseId: "376"}, // DONE
        IPH7102C201: {courseId: "377"}, // DONE
        IPH7102C202: {courseId: "381"}, // DONE
        IPH7102C203: {courseId: "382"}, // DONE
        IPH7106C201: {courseId: "noId1"}, // DONE
        IPH7106C202: {courseId: "noId2"}, // DONE
        IPH7108C201: {courseId: "378"}, // DONE

    }

}

export const hubspotURLUpload = {falmouth: 2067783, qmul: 4159960}
export const studyBlockList = ["Jan 2021", "May 2021"]