import React from 'react';
import { observer } from 'mobx-react';
import SubmitButton from './SubmitButton';
import InputField from './InputField';
import Courses, { studyBlockList, falmouthStudyBlockObject, qmulStudyBlockObject, hubspotURLUpload } from './Courses';
import Common, { selectAll } from './Common';
import * as ReactBootStrap from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar'
import falmouthLogo from './falmouth-logo.png';
import qmulLogo from './qmul-logo.png';
import './App.css';


class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        searchString: '',
        searchType: '',
        stuData: [],
        pushRecords: [],
        resultsNo: 0,
        studyBlock: "",
        isOpen: false,
        uploadCount: 0,
        errorCount: 0,
        uploadRecords: [],
        courseDisabled: true,
        disableSearch: false,
        disableReviewTasks: false,
        retrievalStatus: 'Ready for Search',
        currentLogo: '',
        currentProgress: 0,
        searchMax: 3, // Decide on max search
        daysAhead: 30 // Starts on this when it's called for the first time
    }
    this.recordSelection = this.recordSelection.bind(this);
    this.renderRecords = this.renderRecords.bind(this);
    this.updateRecords = this.updateRecords.bind(this);
  }

  componentDidMount() {

    this.getStudyBlocks();
    //this.getCourseList();

  }

  setInputValue(property, val) {
    val = val.trim();
    this.setState({
        [property]: val
    })
  }

  getStudyBlocks() {

    for (var i = 0; i < studyBlockList.length; i++) {
      var selectionDropdown = document.getElementById("blockSelection");
      var option = document.createElement("option");
      option.text = studyBlockList[i];
      option.setAttribute("id", studyBlockList[i]);
      option.setAttribute("value", studyBlockList[i]);
      selectionDropdown.add(option);
    }

  }

  getCourseList() {
    //console.log(studyBlockObject["Jan2021"]["CEM710"].courseId)

    var selectionDropdown = document.getElementById("blockSelection");
    var strSelection = selectionDropdown.value;
    strSelection = strSelection.replace(/\s/g, '');

    console.log(strSelection);

    var courseCodes = Object.keys(falmouthStudyBlockObject[strSelection]);

    console.log(courseCodes);
    console.log(courseCodes.length);

    for (var i = 0; i < courseCodes.length; i++) {
      var courseName = courseCodes[i];
      var courseId = falmouthStudyBlockObject[strSelection][courseCodes[i]].courseId;
      var selectionDropdown = document.getElementById("courseSelection");
      var option = document.createElement("option");
      option.text = courseName;
      option.setAttribute("id", courseId);
      option.setAttribute("value", courseName);
      selectionDropdown.add(option);
    }
    
  }
  
  async callCanvasAPI() {

    // console.log(this.props.partnerSelection);

    // console.log(hubspotURLUpload[this.props.partnerSelection]);

    if (this.props.partnerSelection === '') {
      alert('Please make sure you have selected a partner at the top');
      return;
    }

    var dataArray = [];

    if (this.state.searchString === '' || this.state.searchType === '') {
      alert('You have not specified a search type and/or search term.');
      return;
    }

    if (this.state.searchType === 'course' && document.getElementById('blockSelection').value === '') {
      alert('Please select a study block and course');
      return;
    }

    if (this.state.searchType === 'course' && document.getElementById('courseSelection').value === '') {
      alert('Please select a course');
      return;
    }

    this.setState ({
      stuData: [],
      resultsNo: 0,
      resultsTest: 0,
      disableSearch: true,
      disableReviewTasks: true,
      retrievalStatus: 'Fetching Students',
      currentProgress: 0
    })

    try {

      let res = await fetch('/getData?search_term=' + this.state.searchString + '&search_type=' + this.state.searchType + '&partner=' + this.props.partnerSelection, {
      })

      let result = await res.json();
      console.log(result);
      console.log(result.data)



      if (this.state.searchType === "studentId") {
        dataArray.push(result.data);
      }
      else {
        dataArray = result.data;
      }
      //let resultNumber = result.data.length;
      
      this.setState ({
        stuData: dataArray,
        resultsNo: dataArray.length,
        resultsTest: dataArray.length,
        retrievalStatus: 'Fetched Matching Students',
        currentProgress: 100
      })

      await this.getPageViews()

      this.setState ({
        disableSearch: false,
        disableReviewTasks: false,
        retrievalStatus: "Data Search Complete",
        currentProgress: 100,
        daysAhead: 30
      })

    }
    catch(e) {
      console.log(e);
    }

  }



  async getPageViews() {

    this.setState ({
      retrievalStatus: 'Fetching First Logins',
      currentProgress: 0
    })

    var fullArray = this.state.stuData;

    for (var i = 0; i < fullArray.length; i++) {

      var created_at = fullArray[i].created_at;
      var canvasId = fullArray[i].id;
      var formattedDate = "";
      var convertDate;
      var txtDate = "";

      if (created_at === "") {
        fullArray[i].first_login = "";
        continue;
      }
  
      if (canvasId === "") {
        fullArray[i].first_login = "";
        continue;
      }

      let setDate = new Date(created_at); // Create Date from Canvas Creation Date
      let forwardDate = new Date(created_at);
      let miscDate = new Date(created_at);
      
      
      let futureDate = new Date(forwardDate.setDate(forwardDate.getDate() + 15));
      let plus30Date = new Date(miscDate.setDate(miscDate.getDate() + 30));
      
      let startAPIDate = setDate.getFullYear() + "-" + ('0' + (setDate.getMonth() + 1)).slice(-2) + "-" + ('0' + setDate.getDate()).slice(-2);
      let finishAPIDate = futureDate.getFullYear() + "-" + ('0' + (futureDate.getMonth() + 1)).slice(-2) + "-" + ('0' + futureDate.getDate()).slice(-2);
      let finishAPIDate30 = plus30Date.getFullYear() + "-" + ('0' + (plus30Date.getMonth() + 1)).slice(-2) + "-" + ('0' + plus30Date.getDate()).slice(-2);

      let resultNumber = 0;
      let searchNumber = 1;
      let completeSearch = false;
      //Do we keep doing a search? If so, how long?

      // Do a search
      while (completeSearch === false) {

        try {

          //Add an if statement to check what search number we are on - NEW
          if (searchNumber > 1) {

            var getNewEndDate = await this.createNewEndDate(setDate)

            finishAPIDate = getNewEndDate;
          }

          console.log('searchNo ' + searchNumber + ' id ' + canvasId + ' start date is ' + startAPIDate);
          console.log('searchNo ' + searchNumber + ' id ' + canvasId + ' finish date is ' + finishAPIDate);

  
          //Would need to pass variables to new function
          let res = await fetch('/getFirstLogin?user_id=' + canvasId + '&start_time=' + startAPIDate + '&end_time=' + finishAPIDate + '&partner=' + this.props.partnerSelection, {
          })
  
          let result = await res.json();
  
          console.log(result);
          console.log(result.data);
  
          if (result.data) {
            resultNumber = result.data.length;
          }
          else {
            fullArray[i].first_login = "";
          }
  
          let lastRecord = resultNumber - 1;
  
          if (resultNumber === 0) {
            console.log('There seems to be no records for this timeframe');
            fullArray[i].first_login = "";
          }
          else {
            formattedDate = result.data[lastRecord].created_at;
            convertDate = new Date(formattedDate);
            txtDate = ('0' + convertDate.getDate()).slice(-2) + "/" + ('0' + (convertDate.getMonth() + 1)).slice(-2) + "/" + convertDate.getFullYear()
            fullArray[i].first_login = txtDate;
            completeSearch = true;
          }
  
          searchNumber++;

          if (searchNumber === this.state.searchMax) {
            completeSearch = true;
          }
  
        }
        catch(e) {
          fullArray[i].first_login = "";
          console.log(e);
        }

      }

      var getPerc = ((i + 1) / fullArray.length) * 100;
      var result = Math.round(getPerc)
      console.log(result)


      this.setState ({
        stuData: fullArray,
        currentProgress: result,
        daysAhead: 30
      })

    }

  }


  async createNewEndDate(updatedDate) {

    let genNewDate = updatedDate;
    let newDays = this.state.daysAhead;
    let increasedDate = new Date(genNewDate.setDate(genNewDate.getDate() + 30));
    let stringDate = increasedDate.getFullYear() + "-" + ('0' + (increasedDate.getMonth() + 1)).slice(-2) + "-" + ('0' + increasedDate.getDate()).slice(-2);

    newDays = newDays + 15;

    this.setState ({
      daysAhead: newDays
    })

    return stringDate;
  
  }


  async callHubSpotAPI(student_identifier) {

    try {

      let res = await fetch('/getIds?stu_id=' + student_identifier + '&partner=' + this.props.partnerSelection, {
      })

      let result = await res.json();
      console.log(result);
      console.log(result.data)

      return result.data

      //let resultNumber = result.data.length;

    }
    catch(e) {
      console.log(e);
    }

  }



  startUploadProcess() {

    // Check there are no outstanding issues
    if (this.state.errorCount > 0) {
      alert('Make sure you have added HubSpot Ids for each entry.');
      return;
    }

    if (this.state.pushRecords.length === 0) {
      alert('Please make sure you have some records ready for upload.');
      return;
    }

    var uploadName = prompt("Please enter a name for the upload.");

    if (uploadName != null) {

      var csvArray = [];
      csvArray.push(['Contact ID','Email','Date of 1st Canvas Login']);

      var table = document.getElementById("recordsList");
      var totalRowCount = table.rows.length;

      for (var i = 0; i < totalRowCount; i++) {
        var hubId = document.getElementById("hubIdRev" + i).value
        var stuEmail = document.getElementById("stuEmailRev" + i).value
        var canLog = document.getElementById("canLogRev" + i).value
        var recArray = [hubId, stuEmail, canLog];
        csvArray.push(recArray);
      }

      this.exportToCSV(`${uploadName}.csv`, csvArray)

    }
    else {
      alert('Please enter a valid name for the upload.');
    }

    // this.setState ({
    //   pushRecords: this.state.uploadRecords
    // })

  }


  async autoContactUpload() {
    alert('This feature is still in progress');
  }

  exportToCSV(filename, rows) {
    var processRow = function (row) {
        var finalVal = '';
        for (var j = 0; j < row.length; j++) {
            var innerValue = row[j] === null ? '' : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            };
            var result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"';
            if (j > 0)
                finalVal += ',';
            finalVal += result;
        }
        return finalVal + '\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
    }

    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } 
    else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
    }
  }


  resetSearch() {

    this.setState ({
      searchString: '',
      searchType: '',
      stuData: [],
      studyBlock: '',
      resultsNo: 0,
      resultsTest: 0,
      courseDisabled: true,
      retrievalStatus: 'Ready for Search',
      currentProgress: 0
    })

    var searchOptions = document.getElementsByName("searchSel");
    for (var i = 0; i < searchOptions.length; i++) {
      searchOptions[i].checked = false;
    }

  }

  async moveToReview() {

    var newRecords = 0; // Just for this update
    var newRecordsArray = []; // Just for this update
    
    this.setState ({ disableReviewTasks: true, currentProgress: 0, retrievalStatus: 'Adding Records to Review' })

    var checkedBoxes = document.querySelectorAll('input[type="checkbox"]:checked');

    for (var i = 0; i < checkedBoxes.length; i++) {
      var chkId = checkedBoxes[i].id;

      if (chkId === "chkSelectAll") {
        continue;
      }

      var topId = chkId.substring(3);
      var stuHubId = "";

      var currentStuId = document.getElementById("stuId" + topId).value


      if (currentStuId !== "") {

        //Call API with Student ID to locate HubSpot Id
        let getHubId = await this.callHubSpotAPI(currentStuId)

        console.log(getHubId);
        console.log(getHubId.results);
        console.log(getHubId.total);

        if (getHubId.total > 0) {

          if (getHubId.results[0].id) {
              stuHubId = getHubId.results[0].id;
          }
          else {
              stuHubId = ""
              this.setState ({
                errorCount: this.state.errorCount + 1
              })
          }

          // Also need to error handle if there are more than 1 records.
        }
        else {
          stuHubId = "";
          this.setState ({
            errorCount: this.state.errorCount + 1
          })
        }

      }
      else {
        stuHubId = "";
        this.setState ({
          errorCount: this.state.errorCount + 1
        })
      }

      var pushRecordEntry = {
        hub_id: stuHubId,
        integration_id: document.getElementById("stuId" + topId).value,
        name: document.getElementById("stuName" + topId).value,
        login_id: document.getElementById("stuEmail" + topId).value,
        created_at: document.getElementById("canLog" + topId).value
      };

      this.state.pushRecords.push(pushRecordEntry);

      //May not need this
      newRecordsArray.push(pushRecordEntry);
      newRecords++;

      var getPerc = ((i + 1) / checkedBoxes.length) * 100;
      var result = Math.round(getPerc)
      console.log(result)

      this.setState ({
        currentProgress: result
      })

    }

    if (newRecords === 0) {
      alert('You have not selected any students');
      this.setState ({ disableReviewTasks: false, currentProgress: 100, retrievalStatus: 'Ready for Search' })
      return;
    }

    this.setState ({ disableReviewTasks: false, currentProgress: 100, retrievalStatus: 'Ready for Search' })
    console.log(this.state.pushRecords);
    alert("Successfully added " + newRecords +  " to the records screen for review.");

  }


  updateCourse() {
    var x = document.getElementById("courseSelection");
    var selCourse = x[x.selectedIndex].id;

    this.setState ({
      searchString: selCourse
    })
  }

  
  errorCheckRecords() {
    console.log("Just checking for errors");

    var errorChkNo = 0;

    var table = document.getElementById("recordsList");
    var totalRowCount = table.rows.length;

    for (var i = 0; i < totalRowCount; i++) {
      var hubId = document.getElementById("hubIdRev" + i).value

      if (hubId === "") {
        errorChkNo++;
      }
    }

    this.setState ({
      errorCount: errorChkNo
    })

  }

  recordSelection(selectedRow) {
    console.log("selectedRow:", selectedRow);

    var currentRecords = this.state.pushRecords;
    currentRecords.splice(selectedRow, 1);

    this.setState ({
      pushRecords: currentRecords
    })

  }


  updateRecords(selectedRow, passVal) {

   var allPush = this.state.pushRecords

   allPush[selectedRow].hub_id = passVal

   this.setState ({
    pushRecords: allPush
  })

  }

  
  renderStudent(student, index) {
    return (
      <tr key={index}>
        <td><input type="checkbox" name="stuRec" id={"chk" + index}></input></td>
        <td class="headerNo"><input type="text" class="form-control" id={"hubId" + index} value={"HubSpot ID"} /></td>
        <td><input type="text" class="form-control" id={"stuId" + index} value={student.integration_id} /></td>
        <td><input type="text" class="form-control" id={"stuName" + index} value={student.name} /></td>
        <td><input type="text" class="form-control" id={"stuEmail" + index} value={student.login_id} /></td>
        <td><input type="text" class="form-control" id={"canLog" + index} value={student.first_login} /></td>
      </tr>
    )
  }

  renderRecords(student, index) {
    return (
      <tr key={index}>
        <td><button onClick={() => this.recordSelection(index)} class="btn-danger" type="button" id={"chkRev" + index}>x</button></td>
        <td><input type="text" class="form-control" id={"hubIdRev" + index} defaultValue={student.hub_id} onChange={(e) => this.updateRecords(index, e.target.value)} /></td>
        <td><input type="text" class="form-control" id={"stuIdRev" + index} value={student.integration_id} /></td>
        <td><input type="text" class="form-control" id={"stuNameRev" + index} value={student.name} /></td>
        <td><input type="text" class="form-control" id={"stuEmailRev" + index} value={student.login_id} /></td>
        <td><input type="text" class="form-control" id={"canLogRev" + index} value={student.created_at} /></td>
      </tr>
    )
  }


  resetTable() {

    this.setState ({
      pushRecords: [],
      currentProgress: 0
    })

  }

  
  openModal = () => this.setState({ isOpen: true });
  
  closeModal = () => this.setState({ isOpen: false });




  render() {

      return (
        <div className='dashboardView'>
            <div className = "row searchRow">
              <div className = "col-md-4">
                <input type="radio" value="Student Name" name="searchSel" onClick={() => this.setState({ searchType: 'name'})} /> Student Name
                <InputField
                    type='text'
                    placeholder='Student Name'
                    onChange={ (val) => this.setInputValue('searchString', val) }
                />
                <br />
                <input type="radio" value="Student ID" name="searchSel" onClick={() => this.setState({ searchType: 'studentId'})} /> Student ID
                <InputField
                    type='text'
                    placeholder='Student ID'
                    onChange={ (val) => this.setInputValue('searchString', val) }
                />
                <br />
              </div>
              <div className = "col-md-4 rowDivider">
                <input type="radio" value="Course" name="searchSel" onClick={() => this.setState({ searchType: 'course', courseDisabled: false})} /> Course
                <select name="blockSelection" style={{marginTop: "16px"}} class="form-control" id="blockSelection" onChange={ () => this.getCourseList() }>
                <option value="" selected disabled hidden>Select Study Block</option>
                </select>
                <select name="courseSelection" style={{marginTop: "16px"}} class="form-control" id="courseSelection" onChange={ () => this.updateCourse() } disabled={this.state.courseDisabled}>
                <option value="" selected disabled hidden>Select Course</option>
                </select>
              </div>
              <div className = "col-md-4">
                <div className = "buttonSection">
                  <SubmitButton
                      text={'Search'}
                      disabled={this.state.disableSearch}
                      onClick={ () => this.callCanvasAPI() }
                    />
                  <SubmitButton
                      text={'Reset'}
                      disabled={false}
                      onClick={ () => this.resetSearch() }
                  />
                  <SubmitButton
                    text={'Open Review Screen'}
                    disabled={this.state.disableReviewTasks}
                    onClick={ () => this.openModal() }
                  />
                  <div className="progressSection">
                    <p class="statusText">Current Status: {this.state.retrievalStatus}</p>
                    <ProgressBar variant="success" now={this.state.currentProgress} />
                  </div>
                </div>
              </div>
            </div>

            <div className = "row">
              <div className = "col-md-12">
              <p class="recordNumber">There are {this.state.resultsNo} results to show.</p>
                <ReactBootStrap.Table striped bordered hover variant="dark">
                  <thead>
                    <th><input type="checkbox" id={"chkSelectAll"} onChange={ () => selectAll() } /></th>
                    <th className={"headerNo"}>Hubspot ID</th>
                    <th>Student ID</th>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th>First Canvas Login</th>
                  </thead>
                  <tbody>
                    {this.state.stuData.map(this.renderStudent)}
                  </tbody>
                </ReactBootStrap.Table>
                
                <p class="recordNumber">There are {this.state.resultsNo} results to show.</p>

                <div class = "selectedBtnArea">
                  <SubmitButton
                      text={'Move Selected to Review'}
                      disabled={this.state.disableReviewTasks}
                      onClick={ () => this.moveToReview() }
                  />
                </div>

              </div>
            </div>

            <Modal show={this.state.isOpen} onHide={this.closeModal}>
              <Modal.Header closeButton className={this.props.partnerSelection}>
                <Modal.Title>Review Screen</Modal.Title>
              </Modal.Header>
              <Modal.Body className={this.props.partnerSelection}>

                <div className = 'row'>
                  <div className = 'col-md-6'>
                  <div className="partnerSelArea">
                      <p><strong>Selected Partner:</strong></p>
                      { this.props.partnerSelection === 'falmouth' && <img src={falmouthLogo} alt="Falmouth Logo" width={150} />}
                      { this.props.partnerSelection === 'qmul' && <img src={qmulLogo} alt="QMUL Logo" width={175} />}
                    </div>
                    <p className="reviewMsg">Please review the entries before uploading to HubSpot.</p>
                    <SubmitButton
                      text={'Add More Records'}
                      disabled={false}
                      onClick={ () => this.closeModal() }
                    />
                    <SubmitButton
                      text={'Reset Table'}
                      disabled={false}
                      onClick={ () => this.resetTable() } 
                    />
                  </div>
                  
                  <div className = 'col-md-6'>

                    <div className="alert alert-danger" role="alert">
                      <p>There are {this.state.errorCount} records without HubSpot Id's.</p>
                      <SubmitButton
                        text={'Revalidate'}
                        disabled={false}
                        onClick={ () => this.errorCheckRecords() }
                      />
                    </div>
                    <div className="alert alert-success" role="alert">
                      <p>There are {this.state.pushRecords.length - this.state.errorCount} records ready to upload.</p>
                      <div class = "uploadButtonArea">
                        <SubmitButton
                          text={'Download CSV File'}
                          disabled={false}
                          onClick={ () => this.startUploadProcess() }
                        />
                        <div class = "submitButton">
                            <a class = 'button form-control hubBtn' href ={'https://app.hubspot.com/import/' + hubspotURLUpload[this.props.partnerSelection] + '/new-import'} target='_blank'>CSV Upload to HubSpot</a>
                          </div>
                        <SubmitButton
                          text={'Auto Upload to HubSpot'}
                          disabled={false}
                          onClick={ () => this.autoContactUpload() }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className = 'row'>
                  <div className = 'col-md-12'>
                    <ReactBootStrap.Table striped bordered hover>
                      <thead>
                        <th></th>
                        <th>Hubspot ID</th>
                        <th>Student ID</th>
                        <th>Name</th>
                        <th>Email Address</th>
                        <th>First Canvas Login</th>
                      </thead>
                      <tbody id="recordsList">
                        {this.state.pushRecords.map(this.renderRecords)}
                      </tbody>
                    </ReactBootStrap.Table>
                  </div>
                </div>

              </Modal.Body>
              <Modal.Footer>
                <SubmitButton
                    text={'Close Window'}
                    disabled={false}
                    onClick={ () => this.closeModal() }
                />
              </Modal.Footer>
            </Modal>

        </div>
      );

    }

}

export default Dashboard;
