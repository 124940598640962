import Dashboard from './Dashboard';

  export const recordSelection = (selectedRow) => {
    console.log("selectedRow:", selectedRow);
  }

  export const selectAll = () => {

    var selectAllChk = document.getElementById('chkSelectAll').checked;
    var checkboxes = document.getElementsByName('stuRec');
    for (var checkbox of checkboxes) {
      checkbox.checked = selectAllChk;
    }

  }


  // Random React Test Code
  // value={this.state.searchString ? this.state.searchString : '' }

